import React from 'react';
// import { withRouter } from 'react-router';
import logo from '../../images/logo_sanasoft.svg';
//import googlePlayBtn from '../../images/googlePlayBtn.png';
//import appStoreBtn from '../../images/appStoreBtn.png';
import {NavLink, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Footer = props => {
    const location = useLocation();
    const pathName = location.pathname.split('/')[1];
    return (
        pathName !== 'preview' ?
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="row justify-content-center align-items-center justify-content-md-center logo"> <img src={logo} alt={"Logo"} height="24px" /> </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-center align-items-center mb-6">
                            <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                                <NavLink to={'/gdpr'}>
                                    <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy policy"/>
                                </NavLink>
                            </div>
                            <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                                <NavLink to={'/gdpr'}>
                                    <FormattedMessage id="footer.termsConditions" defaultMessage="Terms and conditions"/>
                                </NavLink>
                            </div>
                            <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                                <NavLink to={'/gdpr'}>
                                    <FormattedMessage id="footer.impressum" defaultMessage="Impressum"/>
                                </NavLink>
                            </div>
                            <div className="d-inline-flex justify-content-center px-2 px-md-2 px-lg-3">
                                <NavLink to={'/gdpr'}>
                                        <FormattedMessage id="footer.contact" defaultMessage="Contact"/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : null
    );
};

export default Footer;
