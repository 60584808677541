import React from 'react';
import { createSaveApplicationLocaleAction } from '../../actions/Application.actions';
import Header from "./Header";
import {AVAILABLE_LANGUAGES} from "../../config/languages";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";

const HeaderContainer = (props) => {
    const location = useLocation();
    const {application, appointer} = props;

    const pathName = location.pathname.split('/')[1];
    return (
        pathName !== 'preview' ?
            <Header
                {...props}
                location={location}
                appointer={appointer}
                application={application}
                createSaveApplicationLocaleAction={props.createSaveApplicationLocaleAction}
                languages={AVAILABLE_LANGUAGES}
            /> :
            null
    );
}
const mapStateToProps = (state) => (
    {
        application: state.application,
        appointer: state.appointer,
    }
);
export default connect(
    mapStateToProps,
    {
        createSaveApplicationLocaleAction
    }
)(HeaderContainer);