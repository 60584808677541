import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointmentDetailsAction,
    createGetAppointmentVerifyCodeAction,
    createDeleteAppointmentAction,
    createResendSmsAppointmentAction,
} from "../../common/actions/Appointments.actions";
import { ComponentSpinner } from "../../common/components/Spinner/ComponentSpinner";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import { AppointmentCodeVerificationForm } from "./appointmentCodeVerificationForm";
import ModalCenter from "../../common/components/Modal/modalCenter";
import ModalBookingCancel from "../../common/components/ModalBookingCancel/ModalBookingCancel";
import ModalResendSms from "../../common/components/ModalResendSms/ModalResendSms";
import {withRouter} from "../../common/utils/withRouter";

class AppointmentCodeVerification extends Component {
    state = {
        activeModal: '',
    };

    componentDidMount() {
        if(this.props.urlParams) {
            this.props.createGetAppointmentDetailsAction({uuid: this.props.urlParams.uuid, redirect: true, navigate: this.props.navigate});
        }
    }

    submitCodeVerificationConfirmation = (dataObject) => {
        const newData = {
            ...dataObject,
            uuid: this.props.urlParams.uuid,
        };
        this.props.createGetAppointmentVerifyCodeAction({data: newData, navigate: this.props.navigate});
    };

    resendSms = () => {
        this.setState({activeModal: 'resendSms'});
        this.props.modal.onShow();
    };

    cancelBooking = () => {
        this.setState({activeModal: 'cancelBooking'});
        this.props.modal.onShow();
    };

    handleCancelSubmit = (data) => {
        const newData = {
            ...data,
            uuid: this.props.urlParams.uuid,
            navigate: this.props.navigate,
        };
        this.props.createDeleteAppointmentAction(newData);
        this.props.modal.onHide();
    };

    handleResendSmsSubmit = (data) => {
        const newData = {
            ...data,
            uuid: this.props.urlParams.uuid,
        };
        this.props.createResendSmsAppointmentAction(newData);
        this.props.modal.onHide();
    };

    render() {
        let modalContent;
        switch (this.state.activeModal) {
            case 'resendSms':
                modalContent = (<ModalResendSms
                    modal={this.props.modal}
                    onSubmitForm={this.handleResendSmsSubmit}
                    initialValues={{tel: this.props.appointment.user.details.tel}}
                />);
            break;
            case 'cancelBooking':
                modalContent = (<ModalBookingCancel
                    modal={this.props.modal}
                    onSubmitForm={this.handleCancelSubmit}
                />);
            break;
            default:
                modalContent = null;
            break;
        }

        return (
            <Fragment>
                {
                    !this.props.appointmentLoading ?
                        <div className="appointer appointersList row">
                            <AppointerDetails appointer={this.props.appointment} isEmbedded={this.props.isEmbedded}/>
                            <Fragment>
                                <div className="col-10 offset-1 p-0 row">
                                    <div className="col-12 p-3">
                                        <div className="text-center textGray">
                                            <h3>
                                                <FormattedMessage id="appointersListItem.confirmAppointment" defaultMessage="confirm appointment"/>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <AppointmentCodeVerificationForm
                                            appointment={this.props.appointment}
                                            submittedAppointment={this.props.submittedAppointment}
                                            onSubmitForm={this.submitCodeVerificationConfirmation}
                                            resendSms={this.resendSms}
                                            cancelBooking={this.cancelBooking}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                        : <ComponentSpinner type="white"/>
                }
                <ModalCenter {...this.props.modal}>
                    { modalContent }
                </ModalCenter>
            </Fragment>

        );
    }

}

const mapStateToProps = state => ({
    appointment: state.appointment,
    appointmentLoading: state.appointmentLoading,
    submittedAppointment: state.submittedAppointment,
    isEmbedded: state.application.isEmbedded,
});

export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointmentDetailsAction,
        createGetAppointmentVerifyCodeAction,
        createDeleteAppointmentAction,
        createResendSmsAppointmentAction,
    })(AppointmentCodeVerification))));
