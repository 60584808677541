import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { connect } from 'react-redux';
import {IntlProvider} from 'react-intl';
import moment from 'moment/moment';
import  '@formatjs/intl-relativetimeformat/polyfill';
// import de from '@formatjs/intl-relativetimeformat/locale-data/de';
// import en from '@formatjs/intl-relativetimeformat/locale-data/en'
// import it from '@formatjs/intl-relativetimeformat/locale-data/it'
// import fr from '@formatjs/intl-relativetimeformat/locale-data/fr'
// import ro from '@formatjs/intl-relativetimeformat/locale-data/ro'
// import de from 'react-intl/locale-data/de';
// import en from 'react-intl/locale-data/en';
// import it from 'react-intl/locale-data/it';
// import fr from 'react-intl/locale-data/fr';
// import ro from 'react-intl/locale-data/ro';
import {
    NOT_FOUND_ROUTE,
    HOME_ROUTE,
    LOCATION_MAP_SEARCH,
    APPOINTER_ROUTE,
    APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE,
    APPOINTMENT_CONFIRMATION,
    APPOINTMENT_CODE_VERIFICATION,
    APPOINTMENT_CONFIRMED,
    RESET_PASSWORD,
    APPOINTMENT_DETAILS_PAGE,
    RESET_EMAIL,
    TERMS_AND_CONDITIONS,
    GDPR,
    IMPRESSUM,
    CONTACT,
    PREVIEW_APPOINTER_ROUTE,
    EMBED_APPOINTER_ROUTE,
    EMBED_APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE,
    EMBED_APPOINTMENT_CODE_VERIFICATION,
    EMBED_APPOINTMENT_CONFIRMATION,
    EMBED_APPOINTMENT_CONFIRMED,
    EMBED_APPOINTMENT_DETAILS_PAGE, APPOINTER_DOCTOR_ROUTE,
} from "./config/routes";
import { NotFound } from "../pages/notFound/notFound";
import Home from "../pages/home/homeComponent";
import LocationMap from "../pages/locationMap/locationMapComponent";
import Appointer from "../pages/appointer/appointerComponent";
import AppointmentDoctor from "../pages/appointmentDoctor/appointmentDoctor";
import PreviewAppointer from "../pages/previewAppointer/previewAppointerComponent";
import AppointmentFreeSlots from "../pages/appointmentFreeSlot/appointmentFreeSlotsComponent";
import AppointmentConfirmation from "../pages/appointmentConfirmation/appointmentConfirmationComponent";
import AppointmentCodeVerification from "../pages/appointmentCodeVerification/appointmentCodeVerificationComponent";
import AppointmentConfirmed from "../pages/appointmentConfirmed/appointmentConfirmedComponent";
import PasswordReset from "../pages/passwordReset/passwordResetComponent";
import EmailReset from "../pages/emailReset/emailResetComponent";
import AppointmentDetailsComponent from "../pages/appointmentDetails/appointmentDetailsComponent";
import Conditions from "../pages/staticPages/Conditions";
import Impressum from "../pages/staticPages/Impressum";
import Gdpr from "../pages/staticPages/Gdpr";
import Contact from "../pages/contact/contactComponent";
import {
    createApplicationInitAction,
    createSetApplicationLanguageAction,
    createSetEmbeddedApplicationAction,
} from './actions/Application.actions';
import { detectLocale } from "./utils/locale";
import HeaderContainer from "./components/Header/HeaderContainer";
import Footer from "./components/Footer/Footer";
import {Spinner} from "./components/Spinner/Spinner";
import AlertContainer from "./components/Alerts/alertContainer";
import {withRouter} from "./utils/withRouter";


class Router extends Component {
    componentDidMount() {
        // addLocaleData([...en, ...de, ...ro, ...it, ...fr]);

        const pathName = this.props.location.pathname.split('/')[1];

        if (pathName === 'embedded') {
            this.props.createSetEmbeddedApplicationAction(true);
        } else {
            this.props.createSetEmbeddedApplicationAction(false);
        }

        this.props.createApplicationInitAction();
        setTimeout(() => this.getLocale(), 1000);
    }

    getLocale() {
        const selectedLocale = detectLocale();

        this.props.createSetApplicationLanguageAction(selectedLocale);
        moment.locale(selectedLocale);
    }

    renderContent() {
        return this.props.application.loading ?
            <Spinner />
            :
            <Routes>
                <Route path={NOT_FOUND_ROUTE} exact element={<NotFound />} />
                <Route path={LOCATION_MAP_SEARCH} element={<LocationMap />} exact />
                <Route path={APPOINTER_ROUTE} element={<Appointer />} exact />
                <Route path={APPOINTER_DOCTOR_ROUTE} element={<AppointmentDoctor />} exact />
                <Route path={PREVIEW_APPOINTER_ROUTE} element={<PreviewAppointer/>} exact />
                <Route path={EMBED_APPOINTER_ROUTE} element={<Appointer/>} exact />

                <Route path={APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE} element={<AppointmentFreeSlots />} exact />
                <Route path={APPOINTMENT_CONFIRMATION} element={<AppointmentConfirmation />} exact />
                <Route path={APPOINTMENT_DETAILS_PAGE} element={<AppointmentDetailsComponent />} exact />
                <Route path={APPOINTMENT_CODE_VERIFICATION} element={<AppointmentCodeVerification />} exact />
                <Route path={APPOINTMENT_CONFIRMED} element={<AppointmentConfirmed />} exact />

                <Route path={EMBED_APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE} element={<AppointmentFreeSlots />} exact />
                <Route path={EMBED_APPOINTMENT_CONFIRMATION} element={<AppointmentConfirmation />} exact />
                <Route path={EMBED_APPOINTMENT_DETAILS_PAGE} element={<AppointmentDetailsComponent />} exact />
                <Route path={EMBED_APPOINTMENT_CODE_VERIFICATION} element={<AppointmentCodeVerification />} exact />
                <Route path={EMBED_APPOINTMENT_CONFIRMED} element={<AppointmentConfirmed />} exact />

                <Route path={RESET_PASSWORD} element={<PasswordReset />} exact />
                <Route path={RESET_EMAIL} element={<EmailReset />} exact />
                <Route path={HOME_ROUTE} exact element={<Home />} />
                <Route path={TERMS_AND_CONDITIONS} element={<Conditions />} exact />
                <Route path={GDPR} element={<Gdpr />} exact />
                <Route path={IMPRESSUM} element={<Impressum />} exact />
                <Route path={CONTACT} element={<Contact />} exact />
                <Route path="*" element={<NotFound />} />
            </Routes>
    }

    render() {
        const { application } = this.props;
        const intlData = {
            locale: application.locale,
            messages: application.messages,
        };
        return (
            <IntlProvider defaultLocale={application.locale} messages={intlData.messages} locale={intlData.locale}>
                <div>

                    <AlertContainer />
                    <div className="content">
                        <div className="gradient">
                            <HeaderContainer />
                            <div className="container">
                                { this.renderContent() }
                            </div>
                            <Footer />
                        </div>
                    </div>

                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    application: state.application,
});
export default withRouter(connect(mapStateToProps, {createApplicationInitAction, createSetApplicationLanguageAction, createSetEmbeddedApplicationAction})(Router));
