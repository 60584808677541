import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointerDetailsAction,
    createClearAppointerResourcesAction,
} from "../../common/actions/Appointer.actions";
import {Spinner} from "../../common/components/Spinner/Spinner";
import ResourceTypes from "../../common/components/ResourceType/ResourceTypeContainer";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import {withRouter} from "../../common/utils/withRouter";
// import {DEFAULT_COUNTRY} from "../../common/config/languages";

const Appointer  = (props) => {

    useEffect(() => {
        props.createClearAppointerResourcesAction();
        props.createGetAppointerDetailsAction(props.urlParams.uuid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

        return (
            !props.appointerLoading ?
                <div className="appointer appointersList row">
                    <AppointerDetails appointer={props.appointer} isEmbedded={props.isEmbedded}/>
                    <div className="col-12 p-3">
                        <div className="text-center textGray">
                            <h3>
                                <FormattedMessage id="appointersListItem.selectAppointment" defaultMessage="Select appointment"/>
                            </h3>
                        </div>
                    </div>
                    <div className="col-12 p-3">
                        <ResourceTypes preview={false} match={props.urlParams}/>
                    </div>
                </div> :
                <Spinner />

        );

}

const mapStateToProps = state => ({
    appointer: state.appointer,
    appointerLoading: state.appointerLoading,
    isEmbedded: state.application.isEmbedded,
});
export default injectIntl(withModal(withRouter(connect(mapStateToProps, {createGetAppointerDetailsAction,createClearAppointerResourcesAction})(Appointer))));
