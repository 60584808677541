import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import moment from "moment";
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointerDetailsAction,
    createClearAppointerResourcesAction,
    createClearAppointerSelectedResourceAction,
    createSetAppointerSelectedResourceStateAction
} from "../../common/actions/Appointer.actions";
import {
    createSetAppointmentSelectedAction,
} from "../../common/actions/Appointments.actions";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import ResourceTypeAppointment from "../../common/components/ResourceType/ResourceTypeAppointment";
import { ComponentSpinner } from "../../common/components/Spinner/ComponentSpinner";
import {withRouter} from "../../common/utils/withRouter";
import {RESOURCE_TYPES} from "../../common/config/general";

class AppointmentDoctor extends Component {

    constructor(props) {
        super(props);

        moment.locale(this.props.DEFAULT_LANGUAGE);
    }

    componentDidMount() {
        this.props.createClearAppointerResourcesAction();
        this.props.createGetAppointerDetailsAction(this.props.urlParams.uuid);

        if ( !this.props.appointerSelectedResource ) {
            setTimeout(() => {
                const doctors = this.props.appointer.appointer.resource_type_doctors;
                const doctor = doctors.filter((item) => +item.id === +this.props.urlParams.docid);

                if(doctor.length) {
                    doctor[0].children = doctor[0].resource_type_appointments;
                    this.props.createSetAppointerSelectedResourceStateAction(doctor[0]);
                } else {
                    const resourceTypes = this.props.resourceTypes
                    resourceTypes.map((resource) => {
                        if(resource.type === RESOURCE_TYPES.allSpecialties) {
                            const specialities = resource.children.filter((item) => parseInt(item.id) === parseInt(this.props.urlParams.docid))
                            if(specialities.length) {
                                this.props.createSetAppointerSelectedResourceStateAction(specialities[0])
                            }
                        }
                        return true;
                    });
                }
            }, 2500)
        }
    }

    clearSelectedResource = () => {
        this.props.createClearAppointerSelectedResourceAction({navigate: this.props.navigate, uuid: this.props.urlParams.uuid});
        //console.log(this.props.urlParams.uuid);
    };

    selectAppointment = (appointment) => {
        if ( !this.props.preview ) {
            this.props.createSetAppointmentSelectedAction({data: [appointment, this.props.appointer.permalink.split(['/'])[1]], navigate: this.props.navigate});
        }
    };

    render() {
        moment.locale(this.props.DEFAULT_LANGUAGE);

        return (

            this.props.appointerSelectedResource ?
                <div className="appointer appointersList row">
                    <AppointerDetails appointer={this.props.appointer} isEmbedded={this.props.isEmbedded} title={this.props.appointerSelectedResource.title}/>
                    <div className="col-10 col-md-12 col-lg-10 offset-1 offset-md-0 offset-lg-1 p-0 row">
                        <div className="col-12 p-3 backButtonContainer">
                            <div className="backButtonAppointers" onClick={()=> {
                                setTimeout(() => this.clearSelectedResource(), 500);
                            }}>
                            </div>
                            <div className="text-center textGray">
                                <h3>
                                    <FormattedMessage id="appointersListItem.selectAppointment" defaultMessage="Select appointment"/>
                                </h3>
                            </div>
                        </div>
                        <Fragment>
                            <div className="pt-5">
                                <div className="layer2">
                                    {
                                        this.props.appointerSelectedResource.children.map((resource, index) => {
                                            return <ResourceTypeAppointment
                                                key={index}
                                                className="layer2-item"
                                                resource={resource}
                                                appointer={this.props.appointer}
                                                history={this.props.history}
                                                onSelectAppointment={() => this.selectAppointment(resource)}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </div>
            : <ComponentSpinner type={'white'} />
        );
    }

}

const mapStateToProps = state => ({
    appointer: state.appointer,
    isEmbedded: state.application.isEmbedded,
    resourceTypes: state.resourceTypes,
    appointerSelectedResource: state.appointerSelectedResource,
});
export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointerDetailsAction,
        createClearAppointerResourcesAction,
        createSetAppointmentSelectedAction,
        createClearAppointerSelectedResourceAction,
        createSetAppointerSelectedResourceStateAction
    })(AppointmentDoctor))));